* {
  /*-ms-overflow-style: none;*/
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  text-decoration: none;
  
  font-family: 'Inconsolata', monospace;
}

html, body {
  height: 100vh;
  width: 100vw;
  
}

::-webkit-scrollbar:horizontal {
  display: none;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #231621;
  background: #231621;
}
 
::-webkit-scrollbar-thumb {
  background: #C8908A;
  border-radius: 5px; 
}

::-webkit-scrollbar-thumb:hover {
  background: #8A8A94;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
